import React, { type ReactNode } from "react"
import Link from "@material-ui/core/Link"
import * as styles from "./howInstallmentWorks.module.scss"

export interface Steps {
  title?: ReactNode
  subtitle?: ReactNode
  clickToolTip?: boolean
}

export const DEFAULT_STEPS: Steps[] = [
  {
    title: <>Оформите карту</>,
    subtitle: <>Оставьте заявку и получите карту «Халва» с лимитом до 500&nbsp;000 рублей</>,
  },
  {
    title: <>Покупайте с Халвой</>,
    subtitle: <>Совершайте покупки с картой «Халва», а все проценты за вас оплатит магазин</>,
  },
  {
    title: <>Наслаждайтесь покупкой</>,
    subtitle: <>Вносите комфортный платеж равными частями</>,
  },
]

export const STEPS_PRODUCTS_CATEGORIES = [
  {
    title: <>Оставьте заявку</>,
    subtitle: <>Онлайн за несколько минут </>,
  },
  {
    title: <>Получите карту</>,
    subtitle: <>Доставкой или в отделении банка</>,
  },
  {
    title: <>Активируйте карту</>,
    subtitle: <>Совершив покупку в партнерском магазине</>,
  },
]

export const STEPS_SHUBA: Steps[] = [
  {
    title: (
      <>
        Оформляй{" "}
        <strong>
          {" "}
          карту <br /> «Халва»{" "}
        </strong>
      </>
    ),
  },
  {
    title: (
      <>
        Регистрируйся{" "}
        <strong>
          {" "}
          <br /> в&nbsp;Акции
        </strong>
      </>
    ),
  },
  {
    title: (
      <>
        Совершай покупки от&nbsp;500 рублей в&nbsp;любых <br /> магазинах.{" "}
        <strong>Каждая покупка участвует в розыгрыше</strong>
      </>
    ),
  },
]

export const STEPS_ZOON: Steps[] = [
  {
    title: (
      <>
        <span> Оформите карту «Халва», оставив заявку на данной странице </span>
      </>
    ),
  },
  {
    title: (
      <>
        <span>
          Совершите покупку от 1000 руб. в рассрочку в магазинах-партнерах в течение 1-го месяца
          после оформления карты
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        <span> Активируйте промокод </span> ZOON
        <span> в мобильном приложении банка. 1 балл = 1 рубль.</span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_NEDELI_UMNUX_RASSROCHEK: Steps[] = [
  {
    title: (
      <>
        <strong> Активируйте акцию</strong>
        <br /> в мобильном приложении или на&nbsp;странице акции
      </>
    ),
  },
  {
    title: (
      <>
        <strong> Совершайте покупки</strong>
        <br /> с увеличенной рассрочкой на&nbsp;12&nbsp;месяцев в&nbsp;магазинах-партнёрах акции
      </>
    ),
  },
  {
    title: (
      <>
        <strong> Наслаждайтесь покупкой.</strong>
        <br className="d-tab-only-none d-ld-none" /> Вносите комфортный платеж равными частями
      </>
    ),
  },
]

export const STEPS_SHAPKA: Steps[] = [
  {
    title: (
      <>
        <strong>Приходи </strong>в наше зимнее отделение в виде огромной шапки (ВДНХ, возле
        «Бурана»)
      </>
    ),
  },
  {
    title: (
      <>
        <strong>Обращайся </strong>к нашему финансовому консультанту для оформления стикера
      </>
    ),
  },
  {
    title: (
      <>
        <strong> Согревайся</strong> вкусным <br className="d-mob-only-none" /> «Халвакао»!
      </>
    ),
  },
]

export const STEPS_PKW: Steps[] = [
  {
    title: <>Оформите карту онлайн</>,
    subtitle: (
      <>
        Получите Халву с лимитом до 500&nbsp;000 ₽ уже завтра <b>не выходя из дома</b>
      </>
    ),
  },
  {
    title: <>Покупайте с Халвой</>,
    subtitle: (
      <>
        Вы точно найдете <b>всё, что хотите</b> от продуктов и до бытовой техники
        <b> у&nbsp;250&nbsp;000+&nbsp;партнеров</b>
      </>
    ),
  },
  {
    title: <>Наслаждайтесь покупкой</>,
    subtitle: (
      <>
        Проценты за вас <b>заплатит магазин</b>
      </>
    ),
  },
]

export const STEPS_REFIN: Steps[] = [
  {
    subtitle: (
      <>
        <b>
          Погасите свои кредитные карты
          <br />
        </b>
        на сумму до&nbsp;300&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    subtitle: (
      <>
        <b>Оплачивайте Халвой ежемесячно </b>от&nbsp;5&nbsp;покупок на&nbsp;общую&nbsp;сумму
        от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    subtitle: (
      <>
        <b>Наслаждайтесь рассрочкой </b>на&nbsp;24&nbsp;месяца
      </>
    ),
  },
]

export const STEPS_REFIN_KREDITNOY_KARTY: Steps[] = [
  {
    subtitle: (
      <>
        <b>Оформите заявку,</b>
        <br className="d-ld-none" /> получите одобрение <br className="d-ld-none" />{" "}
        на&nbsp;рефинансирование
      </>
    ),
  },
  {
    title: (
      <>
        Погасите свои <br className="d-ld-none" /> кредитные карты
      </>
    ),
    subtitle: <>на сумму до&nbsp;300&nbsp;000&nbsp;₽</>,
  },
  {
    title: <>Вносите платежи</>,
    subtitle: (
      <>
        в рассрочку
        <br className="d-ld-none" /> на&nbsp;24&nbsp;месяца
      </>
    ),
  },
]

export const STEPS_HALVA: Steps[] = [
  {
    title: (
      <>
        Погасите&nbsp;свои кредиты <br className="d-ld-none" />
        <span>на&nbsp;сумму до&nbsp;300&nbsp;000&nbsp;₽</span>
      </>
    ),
  },
  {
    title: (
      <>
        Оплачивайте&nbsp;Халвой ежемесячно{" "}
        <span>от 5 покупок на общую сумму от &nbsp;10&nbsp;000&nbsp;₽</span>
      </>
    ),
  },
  {
    title: (
      <>
        Наслаждайтесь беспроцентной <br /> рассрочкой <span>до&nbsp;24&nbsp;месяцев</span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_REDISIGN_BEZPROCENTOV: Steps[] = [
  {
    title: (
      <>
        <span> Получите карту.</span> В течение <br className="d-sm-none" /> первого месяца
        рассрочка <span>у всех партнеров</span> – 24 месяца
      </>
    ),
  },
  {
    title: (
      <>
        <span> Оплачивайте&nbsp;Халвой </span> ежемесячно от 5 покупок <span> на общую сумму </span>{" "}
        от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    title: (
      <>
        <span> Наслаждайтесь</span> беспроцентной <br /> рассрочкой до&nbsp;24&nbsp;месяцев
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_PKW_REDISIGN: Steps[] = [
  {
    title: (
      <>
        <span> Получите карту.</span> В&nbsp;течение <br className="d-sm-none" /> первого месяца
        рассрочка <br className="d-sm-none" /> <span>у&nbsp;всех партнеров</span> – 24&nbsp;месяца
      </>
    ),
  },
  {
    title: (
      <>
        <span> Оплачивайте&nbsp;Халвой </span> ежемесячно от&nbsp;5 покупок{" "}
        <span> на&nbsp;общую сумму </span> от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    title: (
      <>
        <span>
          {" "}
          Погашайте равными <br className="d-sm-none" /> платежами
        </span>{" "}
        <br className="d-none d-tab-only-block" /> 24&nbsp;месяца
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_LENTA: Steps[] = [
  {
    title: (
      <>
        Оформите карту «Халва»
        <span>
          &nbsp;и привяжите ее <br className="d-none d-large-block" /> к&nbsp;
          <a rel="nofollow noopener" target="_blank" href=" https://lenta.com/lentapay/">
            <u>Лента Pay</u>
          </a>{" "}
          в мобильном приложении «Лента»
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершите в любом магазине «Лента» покупку{" "}
        <span>по Халве после оформления карты в период</span>
        &nbsp;с 23.04 по 20.05.2024
      </>
    ),
  },
  {
    title: (
      <>
        Получите 2000 баллов{" "}
        <span>
          на Карту №1 <br className="d-none d-large-block" /> от «Ленты» до 20.06.2024 ( 1 балл ={" "}
          <br className="d-none d-large-block" /> 1 рубль)
        </span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_NEWYEAR: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <span>в&nbsp;мобильном приложении или&nbsp;на&nbsp;странице акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки{" "}
        <span>с&nbsp;увеличенной рассрочкой на&nbsp;12&nbsp;месяцев у&nbsp;партнеров акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Играйте в&nbsp;новогоднюю игру <span>и&nbsp;получайте промокоды на&nbsp;подарки</span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_STICKER_PAY: Steps[] = [
  {
    title: (
      <>
        Заберите стикер <span> в ближайшем офисе Совкомбанка в течение 10 дней</span>.
      </>
    ),
  },

  {
    title: (
      <>
        Выполните условия{" "}
        <span>
          регулярного шопинга: от&#160;5 покупок на сумму не менее 10&nbsp;000 рублей за месяц.
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        <span>Выполняйте этой условие каждый месяц, чтобы </span>стикер оставался бесплатным!
      </>
    ),
  },
]

export const STEPS_KIBERPONEDELNIK: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <br className="d-ld-none" />{" "}
        <span>в&#160;мобильном приложении или&#160;на&#160;странице&#160;акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки{" "}
        <span>
          в&nbsp;любых интернет-магазинах партнёров и&nbsp;участвуйте в&nbsp;розыгрыше призов
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Крутите&nbsp;колесо&nbsp;фортуны{" "}
        <span>
          на&nbsp;промо-странице и&nbsp;получайте увеличенную рассрочку до&nbsp;36&nbsp;месяцев
        </span>
      </>
    ),
  },
]

export const STEPS_GENDER_ACTION: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию{" "}
        <span>
          в&nbsp;мобильном приложении или{" "}
          <Link
            href=" https://halvacard.ru/shops/actions/keshbek-do-100-na-podarki-lyubimym"
            target="_blank"
            underline="always"
            className={styles.linkBlack}
          >
            на&nbsp;странице акции
          </Link>
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки <span>в&nbsp;любых магазинах партнёров Халвы в&nbsp;период акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Участвуйте в&nbsp;розыгрыше кэшбэка до&nbsp;100%{" "}
        <span> (но не более 50&nbsp;000 баллов. 1&nbsp;балл&nbsp;= 1 руб.)</span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_PROMOPAGE: Steps[] = [
  {
    title: (
      <>
        Оформите карту «Халва» <br />в период<span>&nbsp;1.05.2024 по 31.12.2024</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте промокод из СМС
        <span>
          &nbsp;и примените его к первой покупке от 3000 заемными средствами в магазинах-партнерах
          из списка: Lamoda, Ozon, Яндекс.Маркет, Мегамаркет
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите кэшбэк 500 рублей
        <br />
        <span>он поступит на бонусный счет вашей Халвы </span>
      </>
    ),
  },
]

export const STEPS_PKW_OZON: Steps[] = [
  {
    title: (
      <>
        Оформите карту «Халва» <br />в период<span>&nbsp;с 03.06.2024 по 05.07.2024</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте промокод в мобильном приложении Халвы
        <span>
          &nbsp;и примените его к первой покупке от 3000 заемными средствами в магазинe-партнере
          Ozon
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите кэшбэк 500 рублей
        <br />
        <span>он поступит на бонусный счет вашей Халвы </span>
      </>
    ),
  },
]

export const STEPS_PKW_AVIASALES: Steps[] = [
  {
    title: (
      <>
        Оформи карту «Халва» <br />в период<span>&nbsp;с 20.05.2024 по 07.07.2024</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте промокод в мобильном приложении Халвы
        <span>
          &nbsp;и примените его к первой покупке от 3000 заемными средствами в магазинах-партнерах
          из списка: Lamoda, Ozon, Яндекс.Маркет, Мегамаркет
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите кэшбэк 500 рублей
        <br />
        <span>он поступит на бонусный счет вашей Халвы </span>
      </>
    ),
  },
]

export const STEPS_INVITE_FRIEND: Steps[] = [
  {
    title: (
      <>
        Заполните заявку <br className="d-md-none" />
        <span>
          на оформление
          <br className="d-none d-large-block" /> карты рассрочки «Халва» прямо сейчас
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершите покупки <br className="d-md-none" />
        <span>в&#160;магазинах-партнерах на сумму от 1000 ₽</span>
      </>
    ),
  },
  {
    title: (
      <>
        <span className={styles.toolTipDashed}>Получите 1000 баллов</span>{" "}
        <br className="d-md-none" />
        <span>
          кэшбэка
          <br className="d-none d-large-block" /> на карту рассрочки «Халва»,
          <br className="d-mob-only-none" /> где 1 балл=1 рубль
        </span>
      </>
    ),
    clickToolTip: true,
  },
]

export const STEPS_PKW_BONUS_1500: Steps[] = [
  {
    title: (
      <>
        Оформите карту «Халва» <br />
        <span>
          После получения вам придет промокод
          <br className="d-none d-tab-only-block d-large-block" /> на 1500 баллов
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте промокод
        <br />
        <span>в разделе «Бонусы». Баллы станут доступны при выполнении «Правила 5х10»</span>
      </>
    ),
  },
  {
    title: (
      <>
        Примените баллы <br className="d-none d-tab-only-block" /> на покупку{" "}
        <br className="d-none" />
        <span>
          <br className="d-none d-tab-only-block d-large-block" />
          от 1500 рублей
          <br className="d-none d-tab-only-block" /> в магазинах-партнерах карты «Халва»
        </span>
      </>
    ),
  },
]

export const STEPS_100_CASHBACK: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <span>в мобильном приложении или на странице акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки{" "}
        <span>в любых магазинах-партнёрах и получайте 100% кэшбэк за них в период акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Накопленные баллы{" "}
        <span>
          обменяйте на сертификат, который предоставляет скидку на покупку в магазинах-партнёрах
          акции.
        </span>
      </>
    ),
  },
]

export const STEPS_BTS_AUTO: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <br className="d-none d-tab-only-block d-desk-only-block" /> «Готовимся к
        школе с&nbsp;Халвой» <br className="d-none d-tab-only-block" />
        <span>на&nbsp;странице акции или&nbsp;в&nbsp;приложении</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершите 5 покупок <br /> от&nbsp;10 000 ₽ <br className="d-sm-none" />{" "}
        <span>в&nbsp;магазинах-партнерах</span>
      </>
    ),
  },
  {
    title: (
      <>
        Крутите колесо фортуны <br className="d-none d-tab-only-block" />
        <span>и&nbsp;участвуйте в&nbsp;розыгрыше</span>
      </>
    ),
  },
]

export const STEPS_AUTO_PROMO: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию
        <br className="d-none d-tab-only-block d-desk-only-block" />{" "}
        <span>в&nbsp;мобильном приложении или на&nbsp;странице акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки
        <br className="d-ld-none" />{" "}
        <span>с&nbsp;увеличенной рассрочкой до&nbsp;36&nbsp;месяцев у&nbsp;любых партнеров</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получайте дополнительные промокоды{" "}
        <span>
          на&nbsp;
          <Link
            href="https://fortune.halva.games/actions/gotovimsya_k_shkole_s_halvoj"
            target="_blank"
            underline="always"
            className={styles.blackLink}
          >
            промо-странице
          </Link>{" "}
          на&nbsp;подарки от&nbsp;Халвы и&nbsp;партнеров
        </span>
      </>
    ),
  },
]

export const STEPS_VK_EDUCATION: Steps[] = [
  {
    title: (
      <>
        Оформите&nbsp;карту&nbsp;«Халва»
        <span>
          <br className="d-ld-none" /> в период акции, найдите <br className="d-ld-none" /> промокод
          в мобильном <br className="d-ld-none" /> приложении
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Примените промокод{" "}
        <span>
          <br className="d-none d-tab-only-block" /> к нужной покупке заемными средствами из условий
          акции
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите кэшбэк,{" "}
        <span>
          он поступит <br className="d-none d-md-block" /> <br className="d-sm-none" /> на бонусный
          счет вашей Халвы
        </span>
      </>
    ),
  },
]

export const STEPS_HIPPO: Steps[] = [
  {
    title: (
      <>
        Оформите <br className="d-md-none" />
        <span>карту&nbsp;«Халва»</span>
      </>
    ),
  },
  {
    title: (
      <>
        Оплатите парковку{" "}
        <span>
          «HippoParking» картой в&nbsp;период проведения акции с&nbsp; 08.11.2024 по 30.06.2025
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Начислим кэшбэк 20%{" "}
        <span>
          за&nbsp;каждую оплату парковки по&nbsp;Халве, но&nbsp;не&nbsp;более{" "}
          <br className="d-tab-only-none d-ld-none" /> 1 000 баллов
        </span>
      </>
    ),
  },
]

export const STEPS_BLACK_FRIDAY_HALVA: Steps[] = [
  {
    title: (
      <>
        Оформите карту <span> и&nbsp;активируйте акцию в&nbsp;приложении</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки{" "}
        <span>
          в&nbsp;любых <br className="d-none d-large-block" />
          магазинах-партнерах Халвы
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Радуйтесь покупкам <br />
        <span>
          и&nbsp;выплачивайте сумму <br className="d-none d-large-block" />
          в&nbsp;рассрочку до&nbsp;24&nbsp;месяцев
        </span>
      </>
    ),
  },
]

export const STEPS_PROCENTY_VERNET_MAGAZIN: Steps[] = [
  {
    title: <>Оформите карту «Халва»</>,
  },
  {
    title: (
      <>
        Совершайте покупки <br />
        <span>в&nbsp;магазинах-партнерах Халвы</span>
      </>
    ),
  },
  {
    title: (
      <>
        Радуйтесь покупкам <br />
        <span>
          и&nbsp;выплачивайте их стоимость <br className="d-none d-large-block" />
          в&nbsp;рассрочку до&nbsp;24&nbsp;месяцев
        </span>
      </>
    ),
  },
]

export const STEPS_BLACK_FRIDAY: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <br className="d-ld-none" />
        <span>в мобильном приложении или на&nbsp;странице акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки <span>в&nbsp;любых магазинах-партнерах</span>
      </>
    ),
  },
  {
    title: (
      <>
        Наслаждайтесь <br className="d-md-none" /> беспроцентной рассрочкой{" "}
        <span>на&nbsp;24&nbsp;месяца</span>
      </>
    ),
  },
]

export const STEPS_NEWYEAR_MEDIA: Steps[] = [
  {
    title: (
      <>
        Оформите карту <span>и&nbsp;активируйте акцию в&nbsp;приложении</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки <br className="d-none d-md-block" />{" "}
        <span> в&nbsp;любых магазинах-партнерах Халвы</span>
      </>
    ),
  },
  {
    title: (
      <>
        Играйте в&nbsp;новогоднюю игру <span>и&nbsp;получайте промокоды на&nbsp;подарки</span>
      </>
    ),
  },
]

export const STEPS_NEWYEAR_HALVA: Steps[] = [
  {
    title: (
      <>
        Активируйте акцию <span>в&nbsp;мобильном приложении или на&nbsp;странице акции</span>
      </>
    ),
  },
  {
    title: (
      <>
        Совершайте покупки{" "}
        <span>
          {" "}
          с&nbsp;увеличенной рассрочкой на&nbsp;10&nbsp;месяцев в&nbsp;любых магазинах партнеров
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Играйте в&nbsp;новогоднюю игру <span>и&nbsp;получайте промокоды на&nbsp;подарки</span>
      </>
    ),
  },
]

export const STEPS_RASSROCHKA_S_HALVOI = [
  {
    title: (
      <>
        Оставьте заявку <br />
        <span>Онлайн за несколько минут</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите карту <br />
        <span>Доставкой или в&nbsp;отделении банка</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте карту <br />
        <span>Совершив покупку в&nbsp;партнерском магазине</span>
      </>
    ),
  },
]

export const STEPS_MEDIA = [
  {
    title: (
      <>
        Оставьте заявку. <br />
        <span>Онлайн за несколько минут</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите&nbsp;карту.{" "}
        <span>
          Доставкой
          <br /> или в&nbsp;отделении банка
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте акцию <span> в&nbsp;мобильном приложении</span>
      </>
    ),
  },
]
